.reset-pass-form form {
  /* width: 50%; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 400px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 30px 40px;
  border-radius: 20px;
}
.reset-pass-form {
  display: flex;
  margin: 90px 40px;

  justify-content: center;
}

.reset-pass-form form h3 {
  font-weight: 500;
  font-size: 25px;
}

.reset-pass-form form p {
  font-weight: 300;
  font-size: 13px;
  margin-bottom: 10px;
  margin-top: 5px;
}

.reset-pass-form form p span {
  color: #5f8bbd;
}

.reset-pass-form form label {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 15px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.reset-pass-form form label input {
  border-radius: 3px;
  border: none;
  background-color: #efefef;
  margin-top: 5px;
  padding: 12px 10px;
}

.reset-pass-form form label input[name="wikiid"] {
  background-color: #e6edf0;
}

.reset-pass-form form input:focus {
  outline: none !important;
  box-shadow: 0px 0px 0px 2px #719ece;
}

#usernamehelp {
  font-size: 10px;
}

.reset-pass-form form input[type="submit"] {
  background-color: var(--wikis-blue);
  border: none;
  padding: 11px 20px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  color: white;
  cursor: pointer;
  border-radius: 10px;
  opacity: 0.8;
  font-size: 15px;
}

.reset-pass-form form input[type="submit"]:hover {
  opacity: 1;
}

.reset-pass-form form input[type="submit"]:active {
  transform: scale(0.98);
}

.error-wrapper {
  background: #f16b6b;
  align-items: center;
  vertical-align: middle;
  /* margin-left: -5px; */
  display: flex;
  position: relative;
  border-radius: 5px;
  width: 100%;
}

.error-wrapper span {
  font-size: 12px;
  padding: 6px;
  margin-left: 10px;
  color: hsl(0, 0%, 100%);
}

.error-wrapper::before {
  content: "";
  width: 6px;
  height: 100%;
  background: #ff0000;
  position: absolute;
  border-radius: 5px 0 0 5px;
  left: 0;
}
