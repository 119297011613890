.card-details-container {
  padding: 20px;
  padding-right: 0px;
  display: flex;
  color: black;
}

.extension-card {
  background-color: #ebeef0;

  border-radius: 5px;
  display: flex;
  margin-bottom: 20px;
  position: relative;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.extension-card:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.extension-card img {
  background-color: red;
}

.extension-info {
  padding-right: 50px;
}
.extension-info h4 {
  margin-bottom: 5px;
  font-weight: 500;
}

.extension-info p {
  font-size: 13px;
  font-weight: 300;
}
.extension-icon {
  padding: 25px;
  width: 0px;
  height: 0px;
  background-color: rgb(143, 62, 16);
  margin-right: 20px;
}

.ext-fields-button {
  position: absolute;
  border: none;
  background-color: #e5e5e5;
  width: 23px;
  height: 100%;
  right: 4%;
  transition: transform ease-in 0.2s;
  border-radius: 0px 5px 5px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.ext-fields-button:hover {
  background-color: #79a7e2;
  transform:scale(1.1);

}

.ext-fields-button:hover > svg {
  display: flex;
}

.ext-fields-button svg {
  align-items: center;
  display: none;
  color: #325084;
  transition: all ease-in-out 0.12s;
  /* padding: 2px; */
  font-size: 20px;
}

.ext-delete-button {
  position: absolute;
  border: none;
  background-color: #e5e5e5;
  width: 23px;
  height: 100%;
  right: 0;
  transition: transform ease-in 0.2s;
  border-radius: 0px 5px 5px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.ext-delete-button:hover {
  background-color: rgb(202, 120, 120);
  transform: translateX(4px);
}

.ext-delete-button:hover > svg {
  display: flex;
}

.ext-delete-button svg {
  align-items: center;
  display: none;
  color: #973c28;
  transition: all ease-in-out 0.12s;
  /* padding: 2px; */
  font-size: 20px;
}

.ext-link-button {
  position: absolute;
  border: none;
  background-color: var(--wikis-blue);
  width: 16px;
  height: 100%;
  left: 0;
  transition: transform ease-in 0.2s;
  border-radius: 5px 0px 0px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.ext-link-button:hover {
  transform: translateX(-4px);
}

.ext-link-button:hover > svg {
  display: flex;
}

.ext-link-button svg {
  align-items: center;
  display: flex;
  color: #ffffff;
  transition: all ease-in-out 0.12s;
  /* padding: 2px; */
  font-size: 20px;
}

@media (max-width: 500px) {
  .extension-info h4 {
    font-size: 16px;
  }

  .extension-info p {
    font-size: 12px;
  }
}

.input-box {
  display: block;
  width: 100%;
  padding: 15px 15px;
}
