.config-selection-circle {
  width: 20px;
  height: 20px;
  border: solid 2px #cfcfcf;
  border-radius: 50%;
}

.config-card {
  background-color: #ebebeb;
  /* max-width: 400px; */
  padding: 7px 20px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  border-radius: 20px;
  cursor: pointer;
  border: solid transparent 3px;
  position: relative;
  transition: all 0.3s ease-out;
}

.config-card:hover {
  border: solid #cfcfcf 3px;
}

.config-card:active {
  transform: scale(0.98);
}

.config-card input[type="checkbox"] {
  display: none;
}

.config-label {
  font-size: 12px;
  color: rgb(61, 61, 61);
  margin-left: 15px;
  user-select: none;
  cursor: pointer;
  position: relative;
}

.checkbox-icon {
  width: 18px;
  height: 18px;
  border: solid 2px #cfcfcf;
  border-radius: 50%;
  position: absolute;
  left: 9px;
  transition: 0.3s ease-in-out all;
  transform: scale(1);
}

.checkbox-icon:before {
  content: "\f00c";
  position: absolute;
  width: 100%;
  height: 100%;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 12px;
  color: #000;
  text-align: center;
  opacity: 0;
  transition: 0.2s ease-in-out all;
  transform: scale(2);
}

.config-card input:checked + .checkbox-icon {
  border-color: white;
  transform: scale(1.2);
}

.config-card input:checked + .checkbox-icon:before {
  color: #fff;
  opacity: 1;
  transform: scale(0.8);
}
.cfg-enabl {
  box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0.5);
  border: solid 3px #2ea8c7;
  background-color: #2ea8c7;
}
.cfg-enabl:hover {
  border: solid 3px #2ea8c7;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
}

.cfg-enabl small {
  color: white;
}
