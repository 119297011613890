.skins-container p {
  font-size: 12px;
  margin: 10px 0px;
  color: rgb(122, 122, 122);
}

.skins-container p a {
  position: relative;
  text-decoration: none;
  color: #255c7c;
}

.skin-buttons-wrapper {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  margin-top: 20px;
}

.skins-container {
  margin-right: 50px;
}

.column {
  flex: 25%;
  max-width: 25%;
  padding: 0 10px;
}

.column img {
  margin-top: 8px;
  width: 250px;
  height: 250px;
  object-fit: cover;
  border-radius: 5px;
  object-position: left top;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.column img:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
  rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

@media (max-width: 850px) {
  .skins-container {
    margin-left: 30px;
  }
}
