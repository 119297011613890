.feedback-notif {
  border-radius: 5px;
  margin-bottom: 20px;
  color: white;
  padding: 10px;
  font-size: 12px;
  display: flex;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px 0px;
}

.feedback-notif i {
  font-size: 15px;
  margin-right: 5px;
}

.changes-made {
  background-color: rgb(90, 168, 107);
}

.smthingwentwrong {
  background-color: rgb(241, 85, 74);
}
