:root {
  --pro-color: #dba514;
  --corporate-color: #b0c083;
  --basics-color: #b0b0b0;
  --quantum-color: #b0c083;
  --ultra-color: #6c6c6c;
  --foundation-color: #b0b0b0;

  --card-bg-color: #f4fafd;
}

.card-container {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  border-radius: 35px;
  background-color: var(--card-bg-color);
  transition: transform 0.2s cubic-bezier(0.235, 0, 0.05, 0.95);

  margin: 2rem;
}

.card-container:hover {
  transform: perspective(1px) scale3d(1.022, 1.022, 1) translateZ(0) !important;
}

.first-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 20px 30px 0px;
}

.wiki-name {
  color: #575757;
  display: inline;
  font-size: 25px;
  flex: 50%;
}

.wiki-id {
  color: var(--wikis-blue);
  font-weight: 100;
  flex: 50%;
  margin-left: 30px;
}

/* Plan Stylings */
.plan {
  border-radius: 3px;
  padding: 1px 3px;
  font-weight: 300;
}

.Pro {
  color: var(--pro-color);
  border: 2px solid var(--pro-color);
}

.Corporate {
  color: var(--corporate-color);
  border: 2px solid var(--corporate-color);
}

.Quantum {
  color: var(--quantum-color);
  border: 2px solid var(--quantum-color);
}

.Basics {
  color: var(--basics-color);
  border: 2px solid var(--basics-color);
}

.Foundation {
  color: var(--foundation-color);
  border: 2px solid var(--foundation-color);
}

.Ultra {
  color: var(--ultra-color);
  border: 2px solid var(--ultra-color);
}

.card-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 40px;
  margin-bottom: 25px;
  white-space: normal;
}

.card-button {
  border: none;
  border-radius: 15px;
  color: white;
  padding: 10px 25px;
  font-weight: 100;
  width: auto;
  text-align: center;
  cursor: pointer;
}

.manage-settings {
  background: rgb(32, 136, 200);
  background: linear-gradient(
    90deg,
    rgba(32, 136, 200, 1) 0%,
    rgba(81, 183, 245, 1) 100%
  );
  margin-left: 10px;
}

.administer-wiki {
  background-color: #d7dadd;
  margin-right: 10px;
}

@media (max-width: 376px) {
  .wiki-name {
    font-size: 21px;
  }

  .wiki-id {
    font-size: 15px;
  }

  .plan {
    font-size: 15px;
  }
}
