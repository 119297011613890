.wikis-spinner-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
}

.wikis-spinner-container img {
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
  align-items: center;
}
