.extension-card-av {
  background-color: #ebedf0;
  display: flex;
  margin-bottom: 20px;
  border-radius: 3px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: all 100ms ease-in-out;
  color: black;
  position: relative;
}

.card-details-container {
  padding: 20px;
}

.extension-card-disallowed {
  background-color: #707070a4;
  display: flex;
  margin-bottom: 20px;
  border-radius: 3px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: all 100ms ease-in-out;
  color: black;
  position: relative;
}

.extension-card-disallowed button {
  background-color: #707070;
}

.extension-card-av .extension-info h4 {
  margin-bottom: 5px;
  font-weight: 500;
}

.extension-card-av .extension-icon {
  padding: 25px;
  width: 0px;
  height: 0px;
  background-color: rgb(86, 161, 223);
  margin-right: 20px;
}

.ext-installed {
  position: absolute;
  top: 12px;
  right: 10px;
  /* margin-right: 10px;
  margin-top: 10px; */
  padding: 5px 10px;
  border: none;
  border-radius: 3px;
  width: 100px;
}

.ext-add {
  position: absolute;
  top: 12px;
  right: 10px;
  /* margin-right: 10px;
  margin-top: 10px; */
  padding: 5px 10px;
  border: none;
  border-radius: 3px;
  width: 100px;
}

.ext-add {
  background-color: #d7e4f3;
  cursor: pointer;
}

.ext-add:hover {
  box-shadow: 0px 0px 0px 2px #707070;
}

.ext-installed {
  background-color: #b6cdb5;
}

.ext-disallowed {
  background-color: #707070;
  cursor: pointer;
}

.ext-link-button {
  position: absolute;
  border: none;
  background-color: #5f86e8;
  width: 16px;
  height: 100%;
  left: 0;
  transition: transform ease-in 0.2s;
  border-radius: 5px 0px 0px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.ext-link-button:hover {
  transform: translateX(-4px);
}

.ext-link-button:hover > svg {
  display: flex;
}

.ext-link-button svg {
  align-items: center;
  display: flex;
  color: #ffffff;
  transition: all ease-in-out 0.12s;
  /* padding: 2px; */
  font-size: 20px;
}

@media (max-width: 500px) {
  .extension-card-av .extension-icon {
    padding: 22px;
  }

  .extension-card-av .extension-info {
    padding-right: 0;
  }

  .extension-card-av .extension-info h4 {
    font-size: 15px;
  }

  .extension-card-av .extension-info p {
    font-size: 12px;
    margin: 0;
  }

  .ext-add {
    font-size: 16px;
    padding: 5px;
    text-align: center;
    width: 10%;
  }

  .ext-installed {
    text-align: center;
    width: 10%;
  }

  .extension-info h4 {
    padding-right: 20px;
  }

  .extension-info p {
    padding-right: 20px;
  }
}
