.name-id-container {
  margin-bottom: 20px;
  padding-left: 40px;
}
.name-id-container h1 {
  font-size: 20px;
  font-weight: 500;
}

.name-id-container span {
  color: var(--wikis-blue);
}

.extensions-container {
  max-width: 690px;
  margin-right: 50px;
}

.extensions-container label {
  display: block;
  margin-bottom: 20px;
}

.extensions-skins-container {
  display: flex;
  justify-content: left;
  margin-bottom: 50px;
}

.left-options-container {
  margin: 0px 50px;
  margin-top: 30px;
}

.page-option.enabled {
  color: #3993e7;
}

.left-options-container button {
  display: flex;
  width: 100%;
  text-align: left;
  font-size: 16px;
  border: none;
  padding: 10px 50px 10px 10px;
  margin-bottom: 0px;
  border-radius: 5px;
  justify-content: left;
  align-items: center;
  font-weight: 400;
  background: none;
  text-decoration: none;
  color: black;
}

.left-options-container button svg {
  font-size: 28px;
  margin-right: 3px;
  width: 30px;
}

.left-options-container button:hover {
  background-color: rgb(214, 213, 211);
}

.left-options-container button:active {
  background-color: rgb(170, 170, 170);
}

@media (max-width: 850px) {
  .extensions-skins-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .left-options-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
    background: rgba(141, 137, 137, 0.096);
    border-radius: 5px;
  }

  .page-option.enabled {
    color: white;
    background: #3993e7;
  }

  .left-options-container button:hover {
    background-color: rgb(224, 224, 224);
  }

  .left-options-container button {
    justify-content: center;
    padding: 10px;
    width: 200px;
  }

  .page-option.enabled:hover {
    color: white;
    background: #3993e7;
  }
}

@media (max-width: 650px) {
  .left-options-container button {
    width: 120px;
    font-size: 13px;
  }
}
