.configs-container p {
  font-size: 12px;
  margin: 10px 0px;
  color: rgb(122, 122, 122);
  margin-bottom: 20px;
}
.configs-container p a {
  text-decoration: none;
  color: #255c7c;
}

.configs-container {
  display: flex;
  flex-direction: column;
}

.language-wrapper p {
  margin-bottom: 0px;
}

.configs-wrapper {
  max-width: 600px;
  margin-right: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.configs-container button {
  background-color: #5e92b1;
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 4px;
}
.configs-container button:hover {
  opacity: 0.8;
}
.configs-container button:active {
  transform: scale(0.98);
}

.unapplied-changes-wrapper {
  background: rgb(219, 219, 219);
  background: linear-gradient(
    90deg,
    rgba(219, 219, 219, 1) 43%,
    rgba(255, 255, 255, 1) 100%
  );
  align-items: center;
  vertical-align: middle;
  display: flex;
  position: relative;
  border-radius: 5px 0 0 5px;
}

.unapplied-changes-wrapper span {
  font-size: 12px;
  padding: 10px;
  margin-left: 10px;
}

.unapplied-changes-wrapper::before {
  content: "";
  width: 10px;
  height: 100%;
  background: #d89292;
  position: absolute;
  border-radius: 5px 0 0 5px;
  /* left: 0; */
}

.apply-changes-button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  align-items: center;
  /* align-items: center; */
}

.button-and-reset {
  position: relative;
}

.apply-changes-button-wrapper i {
  position: absolute;
  top: 9px;
  margin-left: 20px;
  font-size: 20px;
  color: #80868a;
  cursor: pointer;
  transition: transform 0.2s ease-out;
}

.apply-changes-button-wrapper i:hover {
  transform: rotate(-40deg);
  color: #b37f7f;
}

@media (max-width: 850px) {
  .configs-container {
    margin-left: 30px;
  }

  .configs-container p {
    margin-right: 50px;
  }
}
