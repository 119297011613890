.menu-button {
  /* position: relative; */
  border: none;
  border-radius: 15px;
  padding: 10px 25px;
  font-weight: 200;
  width: auto;
  text-align: center;
  cursor: pointer;
}

.select-wrapper {
  display: inline-block;
  overflow: hidden;
  margin-left: 10px;
  margin-top: 15px;
  width: fit-content;
}

.select-box {
  border-radius: 5px;
  background: rgb(32, 136, 200);
  background: linear-gradient(
    90deg,
    rgba(32, 136, 200, 1) 0%,
    rgba(81, 183, 245, 1) 100%
  );
  width: fit-content;
  height: 50px;
  border: 0px;
  outline: none;
  color: white;
  font-weight: 400;
  font-size: 16px;
}

.select-wrapper:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  transform: translateY(-1px);
}

.default-skin {
  color: white;
  font-size: 16px;
  font-weight: 400;
  background: var(--wikis-blue);
  width: auto;
  text-align: center;
}

.default-language {
  color: white;
  font-size: 16px;
  font-weight: 400;
  background: var(--wikis-blue);
  width: auto;
  text-align: center;
}

.manage-settings {
  color: white;
  background: rgb(32, 136, 200);
  background: linear-gradient(
    90deg,
    rgba(32, 136, 200, 1) 0%,
    rgba(81, 183, 245, 1) 100%
  );
  margin-left: 10px;
}

.administer-wiki {
  color: black;
}

.dropdown-menu {
  position: absolute;
  list-style: none;
  display: none;
  width: fit-content;
  transition: 0.2s;
  border-radius: 15px;
  background-color: var(--card-bg-color);
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.dropdown-gap {
  height: 5px;
}

.dropdown-manage-settings {
  left: -6%;
}

.dropdown-administer-wiki {
  left: 42%;
}

#dropdown-container:hover > .dropdown-menu {
  display: block;
}

.dropdown-menu li {
  font-size: 12px;
}

.dropdown-menu li a {
  display: block;
  padding: 10px 30px;
  text-align: center;
  text-decoration: none;
  border-bottom: 1px solid #d0d1d1;
  color: rgb(68, 68, 68);
  transition: 0.1s;
  cursor: pointer;
}

.dropdown-menu li:last-child > a {
  border-bottom: none;
}

.dropdown-menu li a:hover {
  color: var(--wikis-blue);
  /* background-color: #2088c8; */
}

@media (max-width: 414px) {
  .menu-button {
    /* position: relative; */

    border: none;
    border-radius: 15px;
    padding: 10px 25px;
    font-weight: 200;
    text-align: center;
    font-size: 12px;
  }

  .dropdown-menu {
    width: 200px;
  }

  .dropdown-manage-settings {
    left: -3%;
  }

  .dropdown-administer-wiki {
    left: 43%;
  }
}

@media (max-width: 376px) {
  .menu-button {
    /* position: relative; */
    font-size: 10px;
  }
  .dropdown-menu {
    width: 150px;
  }

  .dropdown-menu li {
    font-size: 8px;
  }

  .dropdown-manage-settings {
    left: 0%;
  }

  .dropdown-administer-wiki {
    left: 49%;
  }
}
