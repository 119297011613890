@import url(https://fonts.googleapis.com/css2?family=NTR&display=swap);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}

:root {
  --bg-color: #fff;
  --wikis-blue: #51b7f5;
}

body {
  background-color: #fff;
  background-color: var(--bg-color);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}

.form-group input:-webkit-autofill,
.form-group input:-webkit-autofill:hover,
.form-group input:-webkit-autofill:focus,
.form-group input:-webkit-autofill:active {
  /* -webkit-box-shadow: 0 0 0 30px #f1f1de inset !important; */
  /* box-shadow: 0 0 0 30px #47abca0c inset !important; */
  box-shadow: in;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;

  box-shadow: inset 0 0 3px #4679962d;
}

.form-group input {
  outline: none;
  -webkit-appearance: none;
          appearance: none;
  background: none;
}

.form {
  margin-top: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

form {
  display: block;
  position: relative;
}

form .form-inner {
  position: relative;
  display: block;
}

form h2 {
  color: black;
  font-size: 28px;
  font-weight: 500;
  margin: 20px 0px;
  display: flex;
  justify-content: center;
}

form .login-logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

form .form-group .text-field-icon {
  display: flex;
  width: 40px;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
}

form .form-inner .form-group {
  display: flex;
  width: 88%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  border-radius: 10px;
  background-color: #e9ebed;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  position: relative;
}

form .form-inner .form-group input {
  display: block;
  width: 100%;
  padding: 15px 5px;
  border-radius: 0px 10px 10px 0px;

  border: none;
  transition: 0.4s;
}

.form-group input::-webkit-input-placeholder {
  color: #868585;
}

.form-group input::placeholder {
  color: #868585;
}

.form-group input:focus > input {
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
}

.form-group input:focus ~ i {
  display: block;
}

.form-group i {
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 50%;
  width: 29px;
  height: 29px;
  text-align: center;
  vertical-align: center;
  line-height: 30px;
  cursor: pointer;
}

.form-group i:hover {
  background: #cacaca;
}

.form-group:hover {
  border-color: #04befe;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
}

form .form-inner input[type="submit"] {
  display: block;
  padding: 12px 0px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 88%;
  border: none;
  border-radius: 35px;
  background-color: #51b7f5;
  color: white;
  font-size: 20px;
  transition: 0.4s;
  cursor: pointer;
  font-weight: 200;
}

.bn632-hover {
  cursor: pointer;
  margin: 20px;
  height: 55px;
  border: none;
  background-size: 300% 100%;
  border-radius: 50px;
  moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.bn632-hover:hover {
  background-position: 100% 0;
  moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
}

.bn632-hover.bn26 {
  background-image: linear-gradient(
    to right,
    #25aae1,
    #4481eb,
    #04befe,
    #3f86ed
  );
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
}

.copyright-text {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  color: rgb(37, 37, 37);
  font-size: 14px;
}

.alert-box {
  margin-bottom: 10px;
}
.alert-box .alert-content {
  border: 1px solid #721c24;
  padding: 20px 0px;
  background-color: #f8d7da;
  border-radius: 5px;
}
.alert-box h3 {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif;
  font-weight: 500px;
  font-size: 16px;

  display: flex;
  justify-content: center;
  color: #721c24;
}

.alert-box p {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 10px;
  margin-top: 10px;
  color: #721c24;
}

.alert-box a {
  margin-left: 3px;
  color: #2d5aa8;
}

:root {
  --pro-color: #dba514;
  --corporate-color: #b0c083;
  --basics-color: #b0b0b0;
  --quantum-color: #b0c083;
  --ultra-color: #6c6c6c;
  --foundation-color: #b0b0b0;

  --card-bg-color: #f4fafd;
}

.card-container {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  border-radius: 35px;
  background-color: #f4fafd;
  background-color: var(--card-bg-color);
  transition: -webkit-transform 0.2s cubic-bezier(0.235, 0, 0.05, 0.95);
  transition: transform 0.2s cubic-bezier(0.235, 0, 0.05, 0.95);
  transition: transform 0.2s cubic-bezier(0.235, 0, 0.05, 0.95), -webkit-transform 0.2s cubic-bezier(0.235, 0, 0.05, 0.95);

  margin: 2rem;
}

.card-container:hover {
  -webkit-transform: perspective(1px) scale3d(1.022, 1.022, 1) translateZ(0) !important;
          transform: perspective(1px) scale3d(1.022, 1.022, 1) translateZ(0) !important;
}

.first-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 20px 30px 0px;
}

.wiki-name {
  color: #575757;
  display: inline;
  font-size: 25px;
  flex: 50% 1;
}

.wiki-id {
  color: var(--wikis-blue);
  font-weight: 100;
  flex: 50% 1;
  margin-left: 30px;
}

/* Plan Stylings */
.plan {
  border-radius: 3px;
  padding: 1px 3px;
  font-weight: 300;
}

.Pro {
  color: #dba514;
  color: var(--pro-color);
  border: 2px solid #dba514;
  border: 2px solid var(--pro-color);
}

.Corporate {
  color: #b0c083;
  color: var(--corporate-color);
  border: 2px solid #b0c083;
  border: 2px solid var(--corporate-color);
}

.Quantum {
  color: #b0c083;
  color: var(--quantum-color);
  border: 2px solid #b0c083;
  border: 2px solid var(--quantum-color);
}

.Basics {
  color: #b0b0b0;
  color: var(--basics-color);
  border: 2px solid #b0b0b0;
  border: 2px solid var(--basics-color);
}

.Foundation {
  color: #b0b0b0;
  color: var(--foundation-color);
  border: 2px solid #b0b0b0;
  border: 2px solid var(--foundation-color);
}

.Ultra {
  color: #6c6c6c;
  color: var(--ultra-color);
  border: 2px solid #6c6c6c;
  border: 2px solid var(--ultra-color);
}

.card-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 40px;
  margin-bottom: 25px;
  white-space: normal;
}

.card-button {
  border: none;
  border-radius: 15px;
  color: white;
  padding: 10px 25px;
  font-weight: 100;
  width: auto;
  text-align: center;
  cursor: pointer;
}

.manage-settings {
  background: rgb(32, 136, 200);
  background: linear-gradient(
    90deg,
    rgba(32, 136, 200, 1) 0%,
    rgba(81, 183, 245, 1) 100%
  );
  margin-left: 10px;
}

.administer-wiki {
  background-color: #d7dadd;
  margin-right: 10px;
}

@media (max-width: 376px) {
  .wiki-name {
    font-size: 21px;
  }

  .wiki-id {
    font-size: 15px;
  }

  .plan {
    font-size: 15px;
  }
}

.menu-button {
  /* position: relative; */
  border: none;
  border-radius: 15px;
  padding: 10px 25px;
  font-weight: 200;
  width: auto;
  text-align: center;
  cursor: pointer;
}

.select-wrapper {
  display: inline-block;
  overflow: hidden;
  margin-left: 10px;
  margin-top: 15px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.select-box {
  border-radius: 5px;
  background: rgb(32, 136, 200);
  background: linear-gradient(
    90deg,
    rgba(32, 136, 200, 1) 0%,
    rgba(81, 183, 245, 1) 100%
  );
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 50px;
  border: 0px;
  outline: none;
  color: white;
  font-weight: 400;
  font-size: 16px;
}

.select-wrapper:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
}

.default-skin {
  color: white;
  font-size: 16px;
  font-weight: 400;
  background: var(--wikis-blue);
  width: auto;
  text-align: center;
}

.default-language {
  color: white;
  font-size: 16px;
  font-weight: 400;
  background: var(--wikis-blue);
  width: auto;
  text-align: center;
}

.manage-settings {
  color: white;
  background: rgb(32, 136, 200);
  background: linear-gradient(
    90deg,
    rgba(32, 136, 200, 1) 0%,
    rgba(81, 183, 245, 1) 100%
  );
  margin-left: 10px;
}

.administer-wiki {
  color: black;
}

.dropdown-menu {
  position: absolute;
  list-style: none;
  display: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  transition: 0.2s;
  border-radius: 15px;
  background-color: var(--card-bg-color);
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.dropdown-gap {
  height: 5px;
}

.dropdown-manage-settings {
  left: -6%;
}

.dropdown-administer-wiki {
  left: 42%;
}

#dropdown-container:hover > .dropdown-menu {
  display: block;
}

.dropdown-menu li {
  font-size: 12px;
}

.dropdown-menu li a {
  display: block;
  padding: 10px 30px;
  text-align: center;
  text-decoration: none;
  border-bottom: 1px solid #d0d1d1;
  color: rgb(68, 68, 68);
  transition: 0.1s;
  cursor: pointer;
}

.dropdown-menu li:last-child > a {
  border-bottom: none;
}

.dropdown-menu li a:hover {
  color: var(--wikis-blue);
  /* background-color: #2088c8; */
}

@media (max-width: 414px) {
  .menu-button {
    /* position: relative; */

    border: none;
    border-radius: 15px;
    padding: 10px 25px;
    font-weight: 200;
    text-align: center;
    font-size: 12px;
  }

  .dropdown-menu {
    width: 200px;
  }

  .dropdown-manage-settings {
    left: -3%;
  }

  .dropdown-administer-wiki {
    left: 43%;
  }
}

@media (max-width: 376px) {
  .menu-button {
    /* position: relative; */
    font-size: 10px;
  }
  .dropdown-menu {
    width: 150px;
  }

  .dropdown-menu li {
    font-size: 8px;
  }

  .dropdown-manage-settings {
    left: 0%;
  }

  .dropdown-administer-wiki {
    left: 49%;
  }
}

.cards-container {
  align-items: center;
  padding: 50px;

  display: grid;
  grid-template-columns: repeat(auto-fit, 28rem);
  justify-content: center;
}

.content .cards-title h1 {
  margin-left: 50px;
  font-weight: 300;
  color: #7faac3;
  font-size: 23px;
}

hr {
  margin: 0 30px;
  margin-top: 10px;
}

#background:after {
  position: absolute;
  bottom: -100px;
  right: 100px;

  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 1) 20%,
    rgba(255, 255, 255, 0) 80%
  );

  font-size: 500px;
}

.content:after {
  content: "";
  background-image: url(/static/media/W-bg.fe2835a8.svg);
  background-repeat: no-repeat;
  background-position: 90% 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0;
  -webkit-animation: bgFade 1.5s 0.2s forwards;
          animation: bgFade 1.5s 0.2s forwards;
}

@-webkit-keyframes bgFade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes bgFade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 414px) {
  .cards-container {
    align-items: center;
    padding: 50px;

    display: grid;
    grid-template-columns: repeat(auto-fit, 25.5rem);
    justify-content: center;
  }
}

@media (max-width: 376px) {
  .cards-container {
    grid-template-columns: repeat(auto-fit, 23rem);
  }
}

:root {
  --logout-red: #d66657;
}
.navbar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* max-width: calc(100% - 100px); */
  margin: 25px 40px;
}

.navbar-on-login {
  display: none;
}

.navbar-logo img {
  width: 40px;
}

.nav-links {
  display: flex;
  align-items: center;
}

.navbar-logo {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--wikis-blue);
}

.navbar-logo h1 {
  font-size: 23px;
  font-weight: 400;
  margin-left: 10px;
  white-space: nowrap;
}

.menu-items {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.nav-item {
  text-decoration: none;
  color: black;
  font-size: 16px;
  font-weight: 200;
  margin-left: 40px;
}

.nav-item:hover {
  color: var(--wikis-blue);
  transition: all 0.2s ease-out;
}

.logout {
  padding: 7px 15px;
  border: 0.1em solid #d66657;
  border: 0.1em solid var(--logout-red);
  border-radius: 5px;
  text-decoration: none;
  font-weight: 300;
  color: #d66657;
  color: var(--logout-red);
  text-align: center;
  transition: all 0.2s;
  cursor: pointer;
  margin-left: auto;
}

.logout:hover {
  background-color: #d66657;
  background-color: var(--logout-red);
  color: white;
}

.navbar .email {
  color: var(--wikis-blue);
  font-weight: 100;
  font-size: 15px;
  margin-right: 10px;
  text-decoration: none;
}

.email:hover {
  text-decoration: underline;
  cursor: pointer;
}

.menu-toggle {
  position: absolute;
  top: 1.7rem;
  right: 2.2rem;
  flex-direction: column;
  font-size: 35px;
  color: #cd672e;
  display: none;
  cursor: pointer;
}

.menu-toggle:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

@media (max-width: 960px) {
  .menu-toggle {
    display: flex;
  }

  .navbar {
    flex-direction: column;
    align-items: flex-start;
  }

  .menu-items {
    all: unset;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
    height: 140px;
    width: 100%;
    background-color: rgb(241, 243, 243);
    margin-top: 10px;
  }

  .menu-items .nav-links {
    all: unset;
    width: 100%;
    flex-direction: column;
    margin: 0;
  }

  .nav-links a {
    all: unset;
    cursor: pointer;
    display: block;
    width: 100%;
    margin: 0px;
    padding: 0px;
    text-decoration: none;
    color: rgb(97, 96, 96);
    padding: 10px;
    padding-left: 20px;
    font-weight: 100;
  }

  .nav-links a:hover {
    background-color: #51b7f5;
    color: white;
  }

  .collapsed {
    margin-top: 0px;
    height: 0;
  }

  .rightSide {
    display: block;
    align-items: center;
    width: 100%;
    font: inherit;
    padding: 15px 0 10px 20px;
  }

  .rightSide .email {
    width: auto;
  }

  .rightSide .email:hover {
    text-decoration: underline;
  }
}

@media (max-width: 393px) {
  .navbar-logo img {
    width: 35px;
  }

  .navbar-logo h1 {
    font-size: 21px;
  }

  .menu-toggle {
    top: 1.7rem;
    right: 1.5rem;
  }
}
@media (max-width: 360px) {
  .navbar-logo img {
    width: 25px;
  }

  .navbar-logo h1 {
    font-size: 19px;
  }
  .menu-toggle {
    top: 1.5rem;
    right: 1.3rem;
  }
}

.wikis-spinner-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
}

.wikis-spinner-container img {
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
  align-items: center;
}

body.active-modal {
    overflow-y: hidden;
  }
  
  .btn-modal {
    padding: 10px 20px;
    display: block;
    margin: 100px auto 0;
    font-size: 18px;
  }
  
  .modal,
  .overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
  }
  
  .overlay {
    background: rgba(49, 49, 49, 0.8);
  }
  
  .modal-content {
    position: absolute;
    top: 40%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #f1f1f1;
    padding: 20px 50px 20px 20px;
    border-radius: 3px;
    max-width: 600px;
    min-width: 300px;
    overflow: auto;
  }
  
  .close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 7px;
  }
.name-id-container {
  margin-bottom: 20px;
  padding-left: 40px;
}
.name-id-container h1 {
  font-size: 20px;
  font-weight: 500;
}

.name-id-container span {
  color: var(--wikis-blue);
}

.extensions-container {
  max-width: 690px;
  margin-right: 50px;
}

.extensions-container label {
  display: block;
  margin-bottom: 20px;
}

.extensions-skins-container {
  display: flex;
  justify-content: left;
  margin-bottom: 50px;
}

.left-options-container {
  margin: 0px 50px;
  margin-top: 30px;
}

.page-option.enabled {
  color: #3993e7;
}

.left-options-container button {
  display: flex;
  width: 100%;
  text-align: left;
  font-size: 16px;
  border: none;
  padding: 10px 50px 10px 10px;
  margin-bottom: 0px;
  border-radius: 5px;
  justify-content: left;
  align-items: center;
  font-weight: 400;
  background: none;
  text-decoration: none;
  color: black;
}

.left-options-container button svg {
  font-size: 28px;
  margin-right: 3px;
  width: 30px;
}

.left-options-container button:hover {
  background-color: rgb(214, 213, 211);
}

.left-options-container button:active {
  background-color: rgb(170, 170, 170);
}

@media (max-width: 850px) {
  .extensions-skins-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .left-options-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
    background: rgba(141, 137, 137, 0.096);
    border-radius: 5px;
  }

  .page-option.enabled {
    color: white;
    background: #3993e7;
  }

  .left-options-container button:hover {
    background-color: rgb(224, 224, 224);
  }

  .left-options-container button {
    justify-content: center;
    padding: 10px;
    width: 200px;
  }

  .page-option.enabled:hover {
    color: white;
    background: #3993e7;
  }
}

@media (max-width: 650px) {
  .left-options-container button {
    width: 120px;
    font-size: 13px;
  }
}

.extension-card-av {
  background-color: #ebedf0;
  display: flex;
  margin-bottom: 20px;
  border-radius: 3px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: all 100ms ease-in-out;
  color: black;
  position: relative;
}

.card-details-container {
  padding: 20px;
}

.extension-card-disallowed {
  background-color: #707070a4;
  display: flex;
  margin-bottom: 20px;
  border-radius: 3px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: all 100ms ease-in-out;
  color: black;
  position: relative;
}

.extension-card-disallowed button {
  background-color: #707070;
}

.extension-card-av .extension-info h4 {
  margin-bottom: 5px;
  font-weight: 500;
}

.extension-card-av .extension-icon {
  padding: 25px;
  width: 0px;
  height: 0px;
  background-color: rgb(86, 161, 223);
  margin-right: 20px;
}

.ext-installed {
  position: absolute;
  top: 12px;
  right: 10px;
  /* margin-right: 10px;
  margin-top: 10px; */
  padding: 5px 10px;
  border: none;
  border-radius: 3px;
  width: 100px;
}

.ext-add {
  position: absolute;
  top: 12px;
  right: 10px;
  /* margin-right: 10px;
  margin-top: 10px; */
  padding: 5px 10px;
  border: none;
  border-radius: 3px;
  width: 100px;
}

.ext-add {
  background-color: #d7e4f3;
  cursor: pointer;
}

.ext-add:hover {
  box-shadow: 0px 0px 0px 2px #707070;
}

.ext-installed {
  background-color: #b6cdb5;
}

.ext-disallowed {
  background-color: #707070;
  cursor: pointer;
}

.ext-link-button {
  position: absolute;
  border: none;
  background-color: #5f86e8;
  width: 16px;
  height: 100%;
  left: 0;
  transition: -webkit-transform ease-in 0.2s;
  transition: transform ease-in 0.2s;
  transition: transform ease-in 0.2s, -webkit-transform ease-in 0.2s;
  border-radius: 5px 0px 0px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.ext-link-button:hover {
  -webkit-transform: translateX(-4px);
          transform: translateX(-4px);
}

.ext-link-button:hover > svg {
  display: flex;
}

.ext-link-button svg {
  align-items: center;
  display: flex;
  color: #ffffff;
  transition: all ease-in-out 0.12s;
  /* padding: 2px; */
  font-size: 20px;
}

@media (max-width: 500px) {
  .extension-card-av .extension-icon {
    padding: 22px;
  }

  .extension-card-av .extension-info {
    padding-right: 0;
  }

  .extension-card-av .extension-info h4 {
    font-size: 15px;
  }

  .extension-card-av .extension-info p {
    font-size: 12px;
    margin: 0;
  }

  .ext-add {
    font-size: 16px;
    padding: 5px;
    text-align: center;
    width: 10%;
  }

  .ext-installed {
    text-align: center;
    width: 10%;
  }

  .extension-info h4 {
    padding-right: 20px;
  }

  .extension-info p {
    padding-right: 20px;
  }
}

.ext-icon-wrapper {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.ext-icon-fontawesome i {
  font-size: 25px;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f4fafd;
  background: #79a7e2;
  border-radius: 5px;
}

.ext-icon-initial h1 {
  font-size: px;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f4fafd;
  background: #79a7e2;
  border-radius: 5px;
}

.ext-icon-disallowed-fontawesome i {
  font-size: 24px;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: whitesmoke;
  background: #707070;
  border-radius: 5px;
}

.ext-icon-disallowed-initial h1 {
  font-size: 20px;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: whitesmoke;
  background: #707070;
  border-radius: 5px;
}

:root {
  --Popup-background-color: #d7e4f3;
  --Popup-margin: 15px;
  --Popup-arrow-size: 6px;
}

.popup-wrapper {
  display: block;
  position: relative;
}

.popup-box {
  position: absolute;
  border-radius: 4px;
  left: calc(100% + 15px);
  left: calc(100% + var(--Popup-margin));
  top: 50%;
  -webkit-transform: translateX(0) translateY(-50%);
          transform: translateX(0) translateY(-50%);
  padding: 6px;
  color: var(--Popup-text-color);
  background: #d7e4f3;
  background: var(--Popup-background-color);
  font-size: 14px;
  font-family: sans-serif;
  line-height: 1;
  z-index: 100;
  white-space: nowrap;
}

.popup-box::before {
  content: " ";
  left: calc(6px * -1);
  left: calc(var(--Popup-arrow-size) * -1);
  top: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-width: 6px;
  border-width: var(--Popup-arrow-size);
  margin-left: calc(6px * -1);
  margin-left: calc(var(--Popup-arrow-size) * -1);
  -webkit-transform: translateX(0) translateY(-50%);
          transform: translateX(0) translateY(-50%);
  border-right-color: #d7e4f3;
  border-right-color: var(--Popup-background-color);
}

.card-details-container {
  padding: 20px;
  padding-right: 0px;
  display: flex;
  color: black;
}

.extension-card {
  background-color: #ebeef0;

  border-radius: 5px;
  display: flex;
  margin-bottom: 20px;
  position: relative;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.extension-card:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.extension-card img {
  background-color: red;
}

.extension-info {
  padding-right: 50px;
}
.extension-info h4 {
  margin-bottom: 5px;
  font-weight: 500;
}

.extension-info p {
  font-size: 13px;
  font-weight: 300;
}
.extension-icon {
  padding: 25px;
  width: 0px;
  height: 0px;
  background-color: rgb(143, 62, 16);
  margin-right: 20px;
}

.ext-fields-button {
  position: absolute;
  border: none;
  background-color: #e5e5e5;
  width: 23px;
  height: 100%;
  right: 4%;
  transition: -webkit-transform ease-in 0.2s;
  transition: transform ease-in 0.2s;
  transition: transform ease-in 0.2s, -webkit-transform ease-in 0.2s;
  border-radius: 0px 5px 5px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.ext-fields-button:hover {
  background-color: #79a7e2;
  -webkit-transform:scale(1.1);
          transform:scale(1.1);

}

.ext-fields-button:hover > svg {
  display: flex;
}

.ext-fields-button svg {
  align-items: center;
  display: none;
  color: #325084;
  transition: all ease-in-out 0.12s;
  /* padding: 2px; */
  font-size: 20px;
}

.ext-delete-button {
  position: absolute;
  border: none;
  background-color: #e5e5e5;
  width: 23px;
  height: 100%;
  right: 0;
  transition: -webkit-transform ease-in 0.2s;
  transition: transform ease-in 0.2s;
  transition: transform ease-in 0.2s, -webkit-transform ease-in 0.2s;
  border-radius: 0px 5px 5px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.ext-delete-button:hover {
  background-color: rgb(202, 120, 120);
  -webkit-transform: translateX(4px);
          transform: translateX(4px);
}

.ext-delete-button:hover > svg {
  display: flex;
}

.ext-delete-button svg {
  align-items: center;
  display: none;
  color: #973c28;
  transition: all ease-in-out 0.12s;
  /* padding: 2px; */
  font-size: 20px;
}

.ext-link-button {
  position: absolute;
  border: none;
  background-color: var(--wikis-blue);
  width: 16px;
  height: 100%;
  left: 0;
  transition: -webkit-transform ease-in 0.2s;
  transition: transform ease-in 0.2s;
  transition: transform ease-in 0.2s, -webkit-transform ease-in 0.2s;
  border-radius: 5px 0px 0px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.ext-link-button:hover {
  -webkit-transform: translateX(-4px);
          transform: translateX(-4px);
}

.ext-link-button:hover > svg {
  display: flex;
}

.ext-link-button svg {
  align-items: center;
  display: flex;
  color: #ffffff;
  transition: all ease-in-out 0.12s;
  /* padding: 2px; */
  font-size: 20px;
}

@media (max-width: 500px) {
  .extension-info h4 {
    font-size: 16px;
  }

  .extension-info p {
    font-size: 12px;
  }
}

.input-box {
  display: block;
  width: 100%;
  padding: 15px 15px;
}

.enabled-extensions-container h3 {
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: 500;
  /* display: flex; */
}

.enabled-extensions-container h3 span {
  font-size: 12 px;
  float: right;
  margin-right: 10px;
  font-weight: 300;
  color: rgb(139, 139, 139);
}

.enabled-extensions-container {
  margin-bottom: 70px;
}

.available-extensions-container h2 {
  font-size: 16px;
  font-weight: 600;
  padding-left: 8px;
}

.available-extensions-container input {
  margin: 25px 0;
  font-size: 12px;
  font-weight: 600;
  width: 100%;
  border: none;
  background-color: #eff5f9;
  height: 40px;
  border-radius: 50px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 1px;
}

.available-extensions-container input:focus {
  box-shadow: 0 0 2pt 1pt cornflowerblue;
  outline: none;
}

.empty-display {
  display: flex;
  padding: 25px 0px;
  justify-content: center;
  color: rgb(229, 226, 226);
  font-size: 18px;
  align-items: center;
}

.empty-display svg {
  width: 28px;
  height: 28px;
  margin-right: 3px;
}

.available-extensions-container input[type="text"] {
  padding-left: 30px;
  font-weight: 300;
  /* font-size: 10px; */
  letter-spacing: 0.5px;
}

.available-extensions-container input[type="text"]::-webkit-input-placeholder {
  color: rgb(207, 207, 207);
}

.available-extensions-container input[type="text"]::placeholder {
  color: rgb(207, 207, 207);
}

.extensions-container {
  position: relative;
}

@media (max-width: 850px) {
  .extensions-container {
    margin-left: 50px;
  }
}

.feedback-notif {
  border-radius: 5px;
  margin-bottom: 20px;
  color: white;
  padding: 10px;
  font-size: 12px;
  display: flex;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px 0px;
}

.feedback-notif i {
  font-size: 15px;
  margin-right: 5px;
}

.changes-made {
  background-color: rgb(90, 168, 107);
}

.smthingwentwrong {
  background-color: rgb(241, 85, 74);
}

.skin-card {
  /* width: 150px; */
  height: 50px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  margin: 10px;
  border-radius: 5px;

  padding: 0px 20px;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  position: relative;
}

.skin-card:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
}

.skin-card span {
  float: left;
}
.skin-card svg {
  border: none;
  align-items: center;
  display: flex;
  cursor: pointer;
  float: left;
}

.icon-buttons svg {
  margin: 2px;
}

.icon-buttons {
  margin-left: 3px;
}

.skin-card-installed {
  background-color: var(--wikis-blue);
  color: white;
  border-color: var(--wikis-blue);
  cursor: default;
}

.skin-card-uninstalled {
  background-color: #d6d5d5;
  color: #5b5b5b;
  border-color: var(--wikis-blue);
  cursor: default;
}

.skin-delete-btn {
  position: absolute;
  border: none;
  background-color: #49ade5;
  width: 10px;
  height: 100%;
  left: calc(100% - 10px);
  transition: -webkit-transform ease-in 0.2s;
  transition: transform ease-in 0.2s;
  transition: transform ease-in 0.2s, -webkit-transform ease-in 0.2s;
  border-radius: 0px 5px 5px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 0;
  transition: width 0.1s ease-in-out;
}
.skin-delete-btn:hover {
  background-color: rgb(202, 120, 120);
  width: 15px;
}

.skin-delete-btn:hover > svg {
  display: flex;
}

.skin-delete-btn svg {
  align-items: center;
  display: none;
  text-align: center;
  justify-content: center;
  color: #973c28;
}
.skins-container p {
  font-size: 12px;
  margin: 10px 0px;
  color: rgb(122, 122, 122);
}

.skins-container p a {
  position: relative;
  text-decoration: none;
  color: #255c7c;
}

.skin-buttons-wrapper {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  margin-top: 20px;
}

.skins-container {
  margin-right: 50px;
}

.column {
  flex: 25% 1;
  max-width: 25%;
  padding: 0 10px;
}

.column img {
  margin-top: 8px;
  width: 250px;
  height: 250px;
  object-fit: cover;
  border-radius: 5px;
  object-position: left top;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.column img:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
  rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

@media (max-width: 850px) {
  .skins-container {
    margin-left: 30px;
  }
}

.config-selection-circle {
  width: 20px;
  height: 20px;
  border: solid 2px #cfcfcf;
  border-radius: 50%;
}

.config-card {
  background-color: #ebebeb;
  /* max-width: 400px; */
  padding: 7px 20px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  border-radius: 20px;
  cursor: pointer;
  border: solid transparent 3px;
  position: relative;
  transition: all 0.3s ease-out;
}

.config-card:hover {
  border: solid #cfcfcf 3px;
}

.config-card:active {
  -webkit-transform: scale(0.98);
          transform: scale(0.98);
}

.config-card input[type="checkbox"] {
  display: none;
}

.config-label {
  font-size: 12px;
  color: rgb(61, 61, 61);
  margin-left: 15px;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
  position: relative;
}

.checkbox-icon {
  width: 18px;
  height: 18px;
  border: solid 2px #cfcfcf;
  border-radius: 50%;
  position: absolute;
  left: 9px;
  transition: 0.3s ease-in-out all;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.checkbox-icon:before {
  content: "\f00c";
  position: absolute;
  width: 100%;
  height: 100%;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 12px;
  color: #000;
  text-align: center;
  opacity: 0;
  transition: 0.2s ease-in-out all;
  -webkit-transform: scale(2);
          transform: scale(2);
}

.config-card input:checked + .checkbox-icon {
  border-color: white;
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.config-card input:checked + .checkbox-icon:before {
  color: #fff;
  opacity: 1;
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
}
.cfg-enabl {
  box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0.5);
  border: solid 3px #2ea8c7;
  background-color: #2ea8c7;
}
.cfg-enabl:hover {
  border: solid 3px #2ea8c7;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
}

.cfg-enabl small {
  color: white;
}

.configs-container p {
  font-size: 12px;
  margin: 10px 0px;
  color: rgb(122, 122, 122);
  margin-bottom: 20px;
}
.configs-container p a {
  text-decoration: none;
  color: #255c7c;
}

.configs-container {
  display: flex;
  flex-direction: column;
}

.language-wrapper p {
  margin-bottom: 0px;
}

.configs-wrapper {
  max-width: 600px;
  margin-right: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.configs-container button {
  background-color: #5e92b1;
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 4px;
}
.configs-container button:hover {
  opacity: 0.8;
}
.configs-container button:active {
  -webkit-transform: scale(0.98);
          transform: scale(0.98);
}

.unapplied-changes-wrapper {
  background: rgb(219, 219, 219);
  background: linear-gradient(
    90deg,
    rgba(219, 219, 219, 1) 43%,
    rgba(255, 255, 255, 1) 100%
  );
  align-items: center;
  vertical-align: middle;
  display: flex;
  position: relative;
  border-radius: 5px 0 0 5px;
}

.unapplied-changes-wrapper span {
  font-size: 12px;
  padding: 10px;
  margin-left: 10px;
}

.unapplied-changes-wrapper::before {
  content: "";
  width: 10px;
  height: 100%;
  background: #d89292;
  position: absolute;
  border-radius: 5px 0 0 5px;
  /* left: 0; */
}

.apply-changes-button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  align-items: center;
  /* align-items: center; */
}

.button-and-reset {
  position: relative;
}

.apply-changes-button-wrapper i {
  position: absolute;
  top: 9px;
  margin-left: 20px;
  font-size: 20px;
  color: #80868a;
  cursor: pointer;
  transition: -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
}

.apply-changes-button-wrapper i:hover {
  -webkit-transform: rotate(-40deg);
          transform: rotate(-40deg);
  color: #b37f7f;
}

@media (max-width: 850px) {
  .configs-container {
    margin-left: 30px;
  }

  .configs-container p {
    margin-right: 50px;
  }
}

.current-privacy {
    font-size: 20px;
}

@media (min-width: 600px) {
    .privacy-header-row {
        border-top: .5px solid black;
    }

    .plans-table-header-cell {
        border-right: .5px solid black;
    }

    .left-cell {
        border-left: .5px solid black;
    }

}

@media (max-width: 600px) {
    
    .privacy-header-row {
        -webkit-text-decoration-line: underline;
                text-decoration-line: underline;
    }

}

.set-privacy-button {
    background: rgb(32, 136, 200);
    background: linear-gradient(
      90deg,
      rgba(32, 136, 200, 1) 0%,
      rgba(81, 183, 245, 1) 100%
    );
    border: 0px;
    outline: none;
    color: white;
    font-size: 16px;
    border-radius: 0 !important;
  }

  .current-privacy-button {
    background: rgb(32, 136, 200);
    background: linear-gradient(
      90deg,
      rgb(24, 100, 148) 0%,
      rgb(68, 153, 206) 100%
    );
    border: 0px;
    outline: none;
    color: white;
    font-size: 16px;
    border-radius: 0 !important;
  }

.configs-container p {
    margin: 10px 0px;
    color: rgb(122, 122, 122);
    margin-bottom: 20px;
  }
  
  .configs-container {
    display: flex;
    flex-direction: column;
  }
  
  .privacy-span p {
    margin-bottom: 0px;
  }
  
  @media (max-width: 850px) {
    .configs-container {
      margin-left: 30px;
    }
  
    .configs-container p {
      margin-right: 50px;
    }
  }

.plans-table-container {
    margin-top: 1rem;
	position: relative;
}

.plans-table-header-cell {
    font-weight: 600;
    text-align: center;
    flex: 1 1;
}

.plans-table-row {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-basis: 100%;
    border-bottom: .5px solid black;
}

.plans-table-cell {
    display: flex;
    border-right: .5px solid black;
    flex-direction: column;
    padding: 0;
    flex: 1 1;
}

.name-cell {
    border-right: .5px solid black;
}

.cell-hide {
    display: initial;
}

.cell-text {
    text-align: center;
}

.everyone {
    background-color: #cef2e0;
}

.usersonly {
    background-color: #E88E89;
}

.adminapprove {
    background-color: rgba(65, 125, 255, 0.527);
}

.plans-table-cell {
    display: flex;
    justify-content: center;
}
.reset-pass-form form {
  /* width: 50%; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 400px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 30px 40px;
  border-radius: 20px;
}
.reset-pass-form {
  display: flex;
  margin: 90px 40px;

  justify-content: center;
}

.reset-pass-form form h3 {
  font-weight: 500;
  font-size: 25px;
}

.reset-pass-form form p {
  font-weight: 300;
  font-size: 13px;
  margin-bottom: 10px;
  margin-top: 5px;
}

.reset-pass-form form p span {
  color: #5f8bbd;
}

.reset-pass-form form label {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 15px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.reset-pass-form form label input {
  border-radius: 3px;
  border: none;
  background-color: #efefef;
  margin-top: 5px;
  padding: 12px 10px;
}

.reset-pass-form form label input[name="wikiid"] {
  background-color: #e6edf0;
}

.reset-pass-form form input:focus {
  outline: none !important;
  box-shadow: 0px 0px 0px 2px #719ece;
}

#usernamehelp {
  font-size: 10px;
}

.reset-pass-form form input[type="submit"] {
  background-color: var(--wikis-blue);
  border: none;
  padding: 11px 20px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  color: white;
  cursor: pointer;
  border-radius: 10px;
  opacity: 0.8;
  font-size: 15px;
}

.reset-pass-form form input[type="submit"]:hover {
  opacity: 1;
}

.reset-pass-form form input[type="submit"]:active {
  -webkit-transform: scale(0.98);
          transform: scale(0.98);
}

.error-wrapper {
  background: #f16b6b;
  align-items: center;
  vertical-align: middle;
  /* margin-left: -5px; */
  display: flex;
  position: relative;
  border-radius: 5px;
  width: 100%;
}

.error-wrapper span {
  font-size: 12px;
  padding: 6px;
  margin-left: 10px;
  color: hsl(0, 0%, 100%);
}

.error-wrapper::before {
  content: "";
  width: 6px;
  height: 100%;
  background: #ff0000;
  position: absolute;
  border-radius: 5px 0 0 5px;
  left: 0;
}

.container-404 {
  position: absolute;
  top: 40%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center;
}

.container-404 h1 {
  color: var(--wikis-blue);
  font-size: 90px;
}

.container-404 h3 {
  font-size: 25px;
  margin-bottom: 10px;
  font-weight: 300;
}

.container-404 p {
  color: rgb(97, 97, 97);
}

.container-404 img {
  width: 40px;
  margin: 60px 0;
}

.login-button-404 {
  display: inline-block;
  padding: 7px;
  width: 100px;
  margin: 5px;
  font-size: 15px;
  color: #ffffff;
  background-color: var(--wikis-blue);
  border: var(--wikis-blue) 2px solid;

  border-radius: 2px;
  font-weight: 300;
  text-decoration: none;
  transition: box-shadow 200ms ease-out;
  margin-top: 50px;
  opacity: 100%;
}

.contact-button-404 {
  display: inline-block;
  padding: 7px;
  width: 100px;
  margin: 10px;
  font-size: 15px;
  color: var(--wikis-blue);
  border: var(--wikis-blue) 2px solid;
  border-radius: 2px;
  font-weight: 300;
  text-decoration: none;
  transition: box-shadow 200ms ease-out;
  margin-top: 50px;
  opacity: 100%;
}

.login-button-404:hover {
  opacity: 80%;
}

