.ext-icon-wrapper {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.ext-icon-fontawesome i {
  font-size: 25px;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f4fafd;
  background: #79a7e2;
  border-radius: 5px;
}

.ext-icon-initial h1 {
  font-size: px;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f4fafd;
  background: #79a7e2;
  border-radius: 5px;
}

.ext-icon-disallowed-fontawesome i {
  font-size: 24px;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: whitesmoke;
  background: #707070;
  border-radius: 5px;
}

.ext-icon-disallowed-initial h1 {
  font-size: 20px;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: whitesmoke;
  background: #707070;
  border-radius: 5px;
}
