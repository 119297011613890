.enabled-extensions-container h3 {
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: 500;
  /* display: flex; */
}

.enabled-extensions-container h3 span {
  font-size: 12 px;
  float: right;
  margin-right: 10px;
  font-weight: 300;
  color: rgb(139, 139, 139);
}

.enabled-extensions-container {
  margin-bottom: 70px;
}

.available-extensions-container h2 {
  font-size: 16px;
  font-weight: 600;
  padding-left: 8px;
}

.available-extensions-container input {
  margin: 25px 0;
  font-size: 12px;
  font-weight: 600;
  width: 100%;
  border: none;
  background-color: #eff5f9;
  height: 40px;
  border-radius: 50px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 1px;
}

.available-extensions-container input:focus {
  box-shadow: 0 0 2pt 1pt cornflowerblue;
  outline: none;
}

.empty-display {
  display: flex;
  padding: 25px 0px;
  justify-content: center;
  color: rgb(229, 226, 226);
  font-size: 18px;
  align-items: center;
}

.empty-display svg {
  width: 28px;
  height: 28px;
  margin-right: 3px;
}

.available-extensions-container input[type="text"] {
  padding-left: 30px;
  font-weight: 300;
  /* font-size: 10px; */
  letter-spacing: 0.5px;
}

.available-extensions-container input[type="text"]::placeholder {
  color: rgb(207, 207, 207);
}

.extensions-container {
  position: relative;
}

@media (max-width: 850px) {
  .extensions-container {
    margin-left: 50px;
  }
}
