.current-privacy {
    font-size: 20px;
}

@media (min-width: 600px) {
    .privacy-header-row {
        border-top: .5px solid black;
    }

    .plans-table-header-cell {
        border-right: .5px solid black;
    }

    .left-cell {
        border-left: .5px solid black;
    }

}

@media (max-width: 600px) {
    
    .privacy-header-row {
        text-decoration-line: underline;
    }

}

.set-privacy-button {
    background: rgb(32, 136, 200);
    background: linear-gradient(
      90deg,
      rgba(32, 136, 200, 1) 0%,
      rgba(81, 183, 245, 1) 100%
    );
    border: 0px;
    outline: none;
    color: white;
    font-size: 16px;
    border-radius: 0 !important;
  }

  .current-privacy-button {
    background: rgb(32, 136, 200);
    background: linear-gradient(
      90deg,
      rgb(24, 100, 148) 0%,
      rgb(68, 153, 206) 100%
    );
    border: 0px;
    outline: none;
    color: white;
    font-size: 16px;
    border-radius: 0 !important;
  }

.configs-container p {
    margin: 10px 0px;
    color: rgb(122, 122, 122);
    margin-bottom: 20px;
  }
  
  .configs-container {
    display: flex;
    flex-direction: column;
  }
  
  .privacy-span p {
    margin-bottom: 0px;
  }
  
  @media (max-width: 850px) {
    .configs-container {
      margin-left: 30px;
    }
  
    .configs-container p {
      margin-right: 50px;
    }
  }

.plans-table-container {
    margin-top: 1rem;
	position: relative;
}

.plans-table-header-cell {
    font-weight: 600;
    text-align: center;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
}

.plans-table-row {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    border-bottom: .5px solid black;
}

.plans-table-cell {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-right: .5px solid black;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 0;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
}

.name-cell {
    border-right: .5px solid black;
}

.cell-hide {
    display: initial;
}

.cell-text {
    text-align: center;
}

.everyone {
    background-color: #cef2e0;
}

.usersonly {
    background-color: #E88E89;
}

.adminapprove {
    background-color: rgba(65, 125, 255, 0.527);
}

.plans-table-cell {
    display: flex;
    justify-content: center;
}