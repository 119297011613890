* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}

.form-group input:-webkit-autofill,
.form-group input:-webkit-autofill:hover,
.form-group input:-webkit-autofill:focus,
.form-group input:-webkit-autofill:active {
  /* -webkit-box-shadow: 0 0 0 30px #f1f1de inset !important; */
  /* box-shadow: 0 0 0 30px #47abca0c inset !important; */
  box-shadow: in;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;

  box-shadow: inset 0 0 3px #4679962d;
}

.form-group input {
  outline: none;
  appearance: none;
  background: none;
}

.form {
  margin-top: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

form {
  display: block;
  position: relative;
}

form .form-inner {
  position: relative;
  display: block;
}

form h2 {
  color: black;
  font-size: 28px;
  font-weight: 500;
  margin: 20px 0px;
  display: flex;
  justify-content: center;
}

form .login-logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

form .form-group .text-field-icon {
  display: flex;
  width: 40px;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
}

form .form-inner .form-group {
  display: flex;
  width: 88%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  border-radius: 10px;
  background-color: #e9ebed;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  position: relative;
}

form .form-inner .form-group input {
  display: block;
  width: 100%;
  padding: 15px 5px;
  border-radius: 0px 10px 10px 0px;

  border: none;
  transition: 0.4s;
}

.form-group input::placeholder {
  color: #868585;
}

.form-group input:focus > input {
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
}

.form-group input:focus ~ i {
  display: block;
}

.form-group i {
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 50%;
  width: 29px;
  height: 29px;
  text-align: center;
  vertical-align: center;
  line-height: 30px;
  cursor: pointer;
}

.form-group i:hover {
  background: #cacaca;
}

.form-group:hover {
  border-color: #04befe;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
}

form .form-inner input[type="submit"] {
  display: block;
  padding: 12px 0px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 88%;
  border: none;
  border-radius: 35px;
  background-color: #51b7f5;
  color: white;
  font-size: 20px;
  transition: 0.4s;
  cursor: pointer;
  font-weight: 200;
}

.bn632-hover {
  cursor: pointer;
  margin: 20px;
  height: 55px;
  border: none;
  background-size: 300% 100%;
  border-radius: 50px;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.bn632-hover:hover {
  background-position: 100% 0;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  transform: translateY(-2px);
}

.bn632-hover.bn26 {
  background-image: linear-gradient(
    to right,
    #25aae1,
    #4481eb,
    #04befe,
    #3f86ed
  );
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
}

.copyright-text {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  color: rgb(37, 37, 37);
  font-size: 14px;
}

.alert-box {
  margin-bottom: 10px;
}
.alert-box .alert-content {
  border: 1px solid #721c24;
  padding: 20px 0px;
  background-color: #f8d7da;
  border-radius: 5px;
}
.alert-box h3 {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif;
  font-weight: 500px;
  font-size: 16px;

  display: flex;
  justify-content: center;
  color: #721c24;
}

.alert-box p {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 10px;
  margin-top: 10px;
  color: #721c24;
}

.alert-box a {
  margin-left: 3px;
  color: #2d5aa8;
}
