@import url("https://fonts.googleapis.com/css2?family=NTR&display=swap");

.cards-container {
  align-items: center;
  padding: 50px;

  display: grid;
  grid-template-columns: repeat(auto-fit, 28rem);
  justify-content: center;
}

.content .cards-title h1 {
  margin-left: 50px;
  font-weight: 300;
  color: #7faac3;
  font-size: 23px;
}

hr {
  margin: 0 30px;
  margin-top: 10px;
}

#background:after {
  position: absolute;
  bottom: -100px;
  right: 100px;

  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 1) 20%,
    rgba(255, 255, 255, 0) 80%
  );

  font-size: 500px;
}

.content:after {
  content: "";
  background-image: url("/src/W-bg.svg");
  background-repeat: no-repeat;
  background-position: 90% 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0;
  animation: bgFade 1.5s 0.2s forwards;
}

@keyframes bgFade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 414px) {
  .cards-container {
    align-items: center;
    padding: 50px;

    display: grid;
    grid-template-columns: repeat(auto-fit, 25.5rem);
    justify-content: center;
  }
}

@media (max-width: 376px) {
  .cards-container {
    grid-template-columns: repeat(auto-fit, 23rem);
  }
}
