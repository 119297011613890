:root {
  --logout-red: #d66657;
}
.navbar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* max-width: calc(100% - 100px); */
  margin: 25px 40px;
}

.navbar-on-login {
  display: none;
}

.navbar-logo img {
  width: 40px;
}

.nav-links {
  display: flex;
  align-items: center;
}

.navbar-logo {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--wikis-blue);
}

.navbar-logo h1 {
  font-size: 23px;
  font-weight: 400;
  margin-left: 10px;
  white-space: nowrap;
}

.menu-items {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.nav-item {
  text-decoration: none;
  color: black;
  font-size: 16px;
  font-weight: 200;
  margin-left: 40px;
}

.nav-item:hover {
  color: var(--wikis-blue);
  transition: all 0.2s ease-out;
}

.logout {
  padding: 7px 15px;
  border: 0.1em solid var(--logout-red);
  border-radius: 5px;
  text-decoration: none;
  font-weight: 300;
  color: var(--logout-red);
  text-align: center;
  transition: all 0.2s;
  cursor: pointer;
  margin-left: auto;
}

.logout:hover {
  background-color: var(--logout-red);
  color: white;
}

.navbar .email {
  color: var(--wikis-blue);
  font-weight: 100;
  font-size: 15px;
  margin-right: 10px;
  text-decoration: none;
}

.email:hover {
  text-decoration: underline;
  cursor: pointer;
}

.menu-toggle {
  position: absolute;
  top: 1.7rem;
  right: 2.2rem;
  flex-direction: column;
  font-size: 35px;
  color: #cd672e;
  display: none;
  cursor: pointer;
}

.menu-toggle:hover {
  transform: scale(1.05);
}

@media (max-width: 960px) {
  .menu-toggle {
    display: flex;
  }

  .navbar {
    flex-direction: column;
    align-items: flex-start;
  }

  .menu-items {
    all: unset;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
    height: 140px;
    width: 100%;
    background-color: rgb(241, 243, 243);
    margin-top: 10px;
  }

  .menu-items .nav-links {
    all: unset;
    width: 100%;
    flex-direction: column;
    margin: 0;
  }

  .nav-links a {
    all: unset;
    cursor: pointer;
    display: block;
    width: 100%;
    margin: 0px;
    padding: 0px;
    text-decoration: none;
    color: rgb(97, 96, 96);
    padding: 10px;
    padding-left: 20px;
    font-weight: 100;
  }

  .nav-links a:hover {
    background-color: #51b7f5;
    color: white;
  }

  .collapsed {
    margin-top: 0px;
    height: 0;
  }

  .rightSide {
    display: block;
    align-items: center;
    width: 100%;
    font: inherit;
    padding: 15px 0 10px 20px;
  }

  .rightSide .email {
    width: auto;
  }

  .rightSide .email:hover {
    text-decoration: underline;
  }
}

@media (max-width: 393px) {
  .navbar-logo img {
    width: 35px;
  }

  .navbar-logo h1 {
    font-size: 21px;
  }

  .menu-toggle {
    top: 1.7rem;
    right: 1.5rem;
  }
}
@media (max-width: 360px) {
  .navbar-logo img {
    width: 25px;
  }

  .navbar-logo h1 {
    font-size: 19px;
  }
  .menu-toggle {
    top: 1.5rem;
    right: 1.3rem;
  }
}
