.container-404 {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.container-404 h1 {
  color: var(--wikis-blue);
  font-size: 90px;
}

.container-404 h3 {
  font-size: 25px;
  margin-bottom: 10px;
  font-weight: 300;
}

.container-404 p {
  color: rgb(97, 97, 97);
}

.container-404 img {
  width: 40px;
  margin: 60px 0;
}

.login-button-404 {
  display: inline-block;
  padding: 7px;
  width: 100px;
  margin: 5px;
  font-size: 15px;
  color: #ffffff;
  background-color: var(--wikis-blue);
  border: var(--wikis-blue) 2px solid;

  border-radius: 2px;
  font-weight: 300;
  text-decoration: none;
  transition: box-shadow 200ms ease-out;
  margin-top: 50px;
  opacity: 100%;
}

.contact-button-404 {
  display: inline-block;
  padding: 7px;
  width: 100px;
  margin: 10px;
  font-size: 15px;
  color: var(--wikis-blue);
  border: var(--wikis-blue) 2px solid;
  border-radius: 2px;
  font-weight: 300;
  text-decoration: none;
  transition: box-shadow 200ms ease-out;
  margin-top: 50px;
  opacity: 100%;
}

.login-button-404:hover {
  opacity: 80%;
}
