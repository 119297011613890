.skin-card {
  /* width: 150px; */
  height: 50px;
  width: fit-content;
  display: flex;
  align-items: center;
  margin: 10px;
  border-radius: 5px;

  padding: 0px 20px;
  transition: transform 0.2s ease-in-out;
  position: relative;
}

.skin-card:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  transform: translateY(-1px);
}

.skin-card span {
  float: left;
}
.skin-card svg {
  border: none;
  align-items: center;
  display: flex;
  cursor: pointer;
  float: left;
}

.icon-buttons svg {
  margin: 2px;
}

.icon-buttons {
  margin-left: 3px;
}

.skin-card-installed {
  background-color: var(--wikis-blue);
  color: white;
  border-color: var(--wikis-blue);
  cursor: default;
}

.skin-card-uninstalled {
  background-color: #d6d5d5;
  color: #5b5b5b;
  border-color: var(--wikis-blue);
  cursor: default;
}

.skin-delete-btn {
  position: absolute;
  border: none;
  background-color: #49ade5;
  width: 10px;
  height: 100%;
  left: calc(100% - 10px);
  transition: transform ease-in 0.2s;
  border-radius: 0px 5px 5px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 0;
  transition: width 0.1s ease-in-out;
}
.skin-delete-btn:hover {
  background-color: rgb(202, 120, 120);
  width: 15px;
}

.skin-delete-btn:hover > svg {
  display: flex;
}

.skin-delete-btn svg {
  align-items: center;
  display: none;
  text-align: center;
  justify-content: center;
  color: #973c28;
}