* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}

:root {
  --bg-color: #fff;
  --wikis-blue: #51b7f5;
}

body {
  background-color: var(--bg-color);
}
