:root {
  --Popup-background-color: #d7e4f3;
  --Popup-margin: 15px;
  --Popup-arrow-size: 6px;
}

.popup-wrapper {
  display: block;
  position: relative;
}

.popup-box {
  position: absolute;
  border-radius: 4px;
  left: calc(100% + var(--Popup-margin));
  top: 50%;
  transform: translateX(0) translateY(-50%);
  padding: 6px;
  color: var(--Popup-text-color);
  background: var(--Popup-background-color);
  font-size: 14px;
  font-family: sans-serif;
  line-height: 1;
  z-index: 100;
  white-space: nowrap;
}

.popup-box::before {
  content: " ";
  left: calc(var(--Popup-arrow-size) * -1);
  top: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-width: var(--Popup-arrow-size);
  margin-left: calc(var(--Popup-arrow-size) * -1);
  transform: translateX(0) translateY(-50%);
  border-right-color: var(--Popup-background-color);
}
